import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { DropDownItem } from "../../pojo/DropDownItem";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { PartitionPrefGroupI, PartitionPrefI, S25OrgPartitionPrefsService } from "./s25.org.partition.prefs.service";
import { NotificationService } from "../../services/notification.service";
import { S25BulkEditSaveApi } from "../s25-bulk-edit/save/s25.bulk.edit.save.api";

@TypeManagerDecorator("s25-ng-org-partition-prefs")
@Component({
    selector: "s25-ng-org-partition-prefs",
    template: `
        @if (this.init) {
            <div class="partitionPreferences">
                <div class="ngTable">
                    @for (group of this.modelBean.groups; track group; let i = $index) {
                        <div class="ngTableRow">
                            <div class="partitionActions">
                                <div class="ngTableCell ngBold" id="part_group_number_{{ i + 1 }}">{{ i + 1 }}</div>
                                @if (!this.readOnly) {
                                    <div
                                        class="ngTableCell ngBold"
                                        id="part_group_increase_{{ i + 1 }}"
                                        (click)="this.moveGroupUp(i)"
                                        (keyup.enter)="this.moveGroupUp(i)"
                                        tabindex="0"
                                    >
                                        <svg class="c-svgIcon">
                                            <title>Increase Priority</title>
                                            <use
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#arrow--up"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        class="ngTableCell ngBold"
                                        id="part_group_decrease_{{ i + 1 }}"
                                        (click)="this.moveGroupDown(i)"
                                        (keyup.enter)="this.moveGroupDown(i)"
                                        tabindex="0"
                                    >
                                        <svg class="c-svgIcon">
                                            <title>Decrease Priority</title>
                                            <use
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#arrow--down"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        class="ngTableCell ngBold"
                                        id="part_group_delete_{{ i + 1 }}"
                                        (click)="this.deleteGroup(i)"
                                        (keyup.enter)="this.deleteGroup(i)"
                                        tabindex="0"
                                    >
                                        <svg class="c-svgIcon">
                                            <title>Delete Parition Group</title>
                                            <use
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                            />
                                        </svg>
                                    </div>
                                }
                            </div>
                            <div class="ngTableCell">
                                @if (group.prefs?.length > 0) {
                                    @for (partition of group.prefs; track partition; let partIndex = $index) {
                                        <div style="display: flex; justify-content:space-between">
                                            <span id="part_name_{{ partition.itemId }}">{{ partition.itemName }}</span>
                                            @if (!this.readOnly) {
                                                <div
                                                    id="part_delete_{{ partition.itemId }}"
                                                    (click)="this.deletePartition(group, partIndex)"
                                                    (keyup.enter)="this.deletePartition(group, partIndex)"
                                                    tabindex="0"
                                                >
                                                    <svg class="c-svgIcon">
                                                        <title>Delete</title>
                                                        <use
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                                        />
                                                    </svg>
                                                </div>
                                            }
                                        </div>
                                    }
                                }
                                @if (!this.readOnly) {
                                    <s25-ng-dropdown-multi-search-criteria
                                        [type]="'locationPartitions'"
                                        [chosen]="group.prefs"
                                        (done)="updatePartitions(i)"
                                    >
                                    </s25-ng-dropdown-multi-search-criteria>
                                }
                            </div>
                        </div>
                    }
                </div>
                @if (this.showNewButton && !this.readOnly) {
                    <button
                        class="aw-button aw-button--outline c-margin-bottom--single c-margin-top--half"
                        (click)="this.newGroup()"
                    >
                        New Partition Group
                    </button>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OrgPartitionPrefsComponent implements OnInit {
    @Input() modelBean: PartitionPrefI;
    @Input() readOnly?: boolean = false;
    @Input() alwaysEditable?: boolean = true;
    @Input() doSave = false;
    @Output() modelBeanChange = new EventEmitter<PartitionPrefI>();

    init = false;
    buttonText = "Update Partition Preferences";
    showNewButton: boolean;
    private newQueryId: number; //used to store the queryId for the current session

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit(): void {
        this.modelBean = this.modelBean || { groups: [] };
        this.modelBean.groups = S25Util.array.forceArray(this.modelBean.groups);
        for (const group of this.modelBean.groups) {
            group.prefs = S25Util.array.forceArray(group.prefs);
        }

        this.toggleNewButton();

        this.init = true;
        this.modelBeanChange.emit(this.modelBean);
        this.cd.detectChanges();
    }

    //Moves a group up in priority by 1. eg.(1 to 0)
    moveGroupUp(index: number) {
        0 < index && S25Util.array.move(this.modelBean.groups, index, index - 1);
        this.done();
    }

    //Moves group down in priority by one. Eg( 3 to 4)
    moveGroupDown(index: number) {
        3 > index && S25Util.array.move(this.modelBean.groups, index, index + 1);
        this.done();
    }

    deleteGroup(index: number) {
        S25Util.array.remove(this.modelBean.groups, index);
        this.toggleNewButton();
        this.done();
    }

    newGroup() {
        let newGroup: PartitionPrefGroupI = {
            prefs: [],
            groupId: this.modelBean.groups.length,
        };
        this.modelBean.groups.push(newGroup);
        this.showNewButton = false;

        this.cd.detectChanges();
    }

    deletePartition(group: PartitionPrefGroupI, index: number) {
        S25Util.array.remove(group.prefs, index);
        if (group.prefs.length == 0) {
            this.deleteGroup(group.groupId - 1);
        } else {
            this.done();
        }
    }

    updatePartitions(groupIndex: number) {
        // If added partition already exists, remove from lower priority group and notify
        // Partition groups are sorted in order of priority, with higher priority having a lower index
        const priority: Map<number | string, number> = new Map();
        for (const [i, group] of S25Util.array.enumerate(this.modelBean.groups)) {
            const addedToThisGroup = groupIndex === i;
            const toDelete: Set<DropDownItem> = new Set();
            for (const partition of group.prefs) {
                const hasHigherPriority = priority.has(partition.itemId);
                if (hasHigherPriority) {
                    if (addedToThisGroup) {
                        const msg = `${partition.itemName} already exists in partition group ${priority.get(partition.itemId) + 1} and will not be added to group ${i + 1}`;
                        NotificationService.post(msg);
                    } else {
                        const msg = `${partition.itemName} will be removed from partition group ${i + 1} and will be added to group ${priority.get(partition.itemId) + 1}`;
                        NotificationService.post(msg);
                    }
                    toDelete.add(partition);
                }
                priority.set(partition.itemId, i);
            }
            S25Util.array.inplaceFilter(group.prefs, (partition) => !toDelete.has(partition));
        }

        this.toggleNewButton();
        this.done();
    }

    toggleNewButton() {
        this.showNewButton =
            this.modelBean.groups.length == 0 ||
            (this.modelBean.groups.length < 4 &&
                this.modelBean.groups[this.modelBean.groups.length - 1].prefs.length > 0);
    }

    async done() {
        if (this.doSave) {
            //Bulk edit and R25/Admin tool set 1 queryId for multiple orgs. Make sure we aren't changing other org's prefs by accident by creating a new query Id.
            //If this isn't the first save of the session re-use the queryId
            this.newQueryId = await S25OrgPartitionPrefsService.savePrefs(
                this.modelBean,
                [this.modelBean.orgId],
                this.newQueryId,
                !!this.newQueryId, //preserve search if there is an existing queryId
            );
            this.modelBeanChange.emit(this.modelBean);
        } else {
            this.modelBeanChange.emit(this.modelBean);
            S25BulkEditSaveApi.reset(this.elementRef.nativeElement);
        }
        this.cd.detectChanges();
    }
}
