import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { GridListItem, GridsService } from "../../services/grids.service";
import { Bind } from "../../decorators/bind.decorator";
import { DropDownItem } from "../../pojo/DropDownItem";

@Component({
    selector: "s25-ng-share-academic-grid",
    template: `
        <div>
            <div class="shareWith">
                <label>Share With:</label>
                <s25-ng-dropdown-search-criteria
                    [type]="'contacts'"
                    [customFilterValue]="'&is_r25user=1'"
                    [noSelection]="'Select from Users'"
                    [filter]="filter"
                    (chosenChange)="addShare($event)"
                />
            </div>
            <div class="users">
                <label>User</label>
                <div></div>
                @for (sharee of tempShares; track sharee.contId) {
                    <div>{{ sharee.name }}</div>
                    <s25-ng-button [type]="'danger--outline'" (click)="removeShare($index)"> Remove </s25-ng-button>
                }
            </div>
        </div>
        <div class="buttons">
            <s25-ng-button [type]="'primary'" [onClick]="share">Save</s25-ng-button>
            <s25-ng-button [type]="'outline'" (click)="cancelled.emit()">Cancel</s25-ng-button>
        </div>
    `,
    styles: `
        :host {
            min-height: min(30rem, 75svh);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        label {
            font-weight: bold;
        }

        .shareWith {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            align-items: center;
            padding-bottom: 1rem;
        }

        .shareWith s25-ng-dropdown-search-criteria {
            flex: 1;
        }

        .users {
            display: grid;
            gap: 0.5rem;
            grid-template-columns: 1fr 5rem;
        }

        .buttons {
            display: flex;
            justify-content: end;
            gap: 0.5rem;
            padding-block: 0.5rem;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25AcademicGridsShareComponent implements OnChanges {
    @Input({ required: true }) id: number;
    @Input({ required: true }) sharedWith: GridListItem["sharedWith"];

    @Output() saved = new EventEmitter<void>();
    @Output() cancelled = new EventEmitter<void>();
    @Output() sharedWithChange = new EventEmitter<GridListItem["sharedWith"]>();

    tempShares: GridListItem["sharedWith"];

    constructor(private cd: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.sharedWith && !!changes.sharedWith.currentValue) {
            this.tempShares = this.sharedWith.slice();
        }
    }

    addShare(item: DropDownItem) {
        this.tempShares.push({ grid: this.id, contId: Number(item.itemId), name: item.itemName });
    }

    removeShare(index: number) {
        this.tempShares.splice(index, 1);
        this.cd.detectChanges();
    }

    @Bind
    async share() {
        const resp = await GridsService.setShared(
            this.id,
            this.tempShares.map((share) => share.contId),
        );
        if (resp.error) {
            alert("Something went wrong when sharing the grid");
        } else {
            this.sharedWithChange.emit(this.tempShares);
            this.tempShares = this.tempShares.slice();
            this.saved.emit();
        }
    }

    @Bind
    filter(item: DropDownItem) {
        return !this.tempShares.some((share) => share.contId === item.itemId);
    }
}
