import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { GridListItem, GridsService } from "../../services/grids.service";
import { DropDownItem } from "../../pojo/DropDownItem";
import { Bind } from "../../decorators/bind.decorator";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DeepPartial } from "../../pojo/Util";
import { S25Util } from "../../util/s25-util";

@Component({
    selector: "s25-ng-create-academic-grid",
    template: `
        <form [formGroup]="form" (submit)="onSave()">
            <label for="createAcademicGridName">Name:</label>
            <div class="input-wrapper">
                <input id="createAcademicGridName" [formControlName]="'name'" class="c-input" />
                @if (name.invalid && (name.dirty || name.touched)) {
                    @if (name.hasError("required")) {
                        <span class="error-message">Enter a name</span>
                    }
                }
            </div>

            <label>Event Search: </label>
            <div class="input-wrapper">
                @if (mode === "create") {
                    <s25-ng-search-dropdown
                        [type]="'event'"
                        [allowNonQueryId]="false"
                        [formControlName]="'eventQuery'"
                    />
                    @if (eventQuery.invalid && (eventQuery.dirty || eventQuery.touched)) {
                        @if (eventQuery.hasError("required")) {
                            <span class="error-message">Select an event search</span>
                        }
                    }
                } @else {
                    <span>{{ eventQuery.value.itemName }}</span>
                }
            </div>
        </form>

        <div class="footer">
            <s25-ng-button [type]="'primary'" [onClick]="onSave">Save</s25-ng-button>
            <s25-ng-button [type]="'danger--outline'" (click)="done.emit()">Cancel</s25-ng-button>
        </div>
    `,
    styles: `
        :host {
            display: grid;
            align-content: space-between;
            min-height: 25rem; /* for the search dropdown... */
        }

        form {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-column-gap: 1rem;
            grid-row-gap: 0.5rem;
        }

        .input-wrapper {
            display: grid;
            max-width: 20em;
        }

        .error-message {
            color: var(--error-message-color);
            font-size: 0.8em;
        }

        .footer {
            display: flex;
            justify-content: right;
            gap: 0.5rem;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25CreateAcademicGridComponent implements OnChanges {
    @Input({ required: false }) grid: DeepPartial<GridListItem> & Pick<GridListItem, "id">;
    @Input({ required: false }) mode: "create" | "edit" = "create";

    @Output() done = new EventEmitter<GridListItem>();

    form = new FormGroup({
        name: new FormControl<string>("", Validators.required),
        eventQuery: new FormControl<DropDownItem>(undefined, Validators.required),
    });
    type: "schedule-only" = "schedule-only";

    constructor(private cd: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.grid?.currentValue) {
            this.form.controls.name.setValue(this.grid.name);
            this.form.controls.eventQuery.setValue({
                itemName: this.grid.eventQuery.queryName,
                itemId: this.grid.eventQuery.queryId,
            });
        }
        if (changes.mode?.currentValue) {
            if (changes.mode.currentValue === "edit" && !this.grid?.id) {
                throw new Error("Grid ID is required in edit mode");
            }
        }
    }

    @Bind
    async onSave() {
        if (!this.form.dirty) this.done.emit(); // Save with no changes => cancel

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const formData = S25Util.reactiveForms.getDirtyValues(this.form);
        const data: DeepPartial<GridListItem> = {
            name: formData.name,
            eventQuery: formData.eventQuery ? { queryId: Number(formData.eventQuery.itemId) } : undefined,
        };

        if (this.mode === "create") {
            data.type = this.type;
            const [newGrid, err] = await GridsService.create(data);
            if (err) {
                alert("Something went wrong when creating your grid.");
                return;
            }
            this.done.emit(newGrid);
        } else if (this.mode === "edit") {
            data.id = this.grid.id;
            const [newGrid, err] = await GridsService.update(data as typeof this.grid);
            if (err) {
                alert("Something went wrong when updating your grid.");
                return;
            }
            this.done.emit(newGrid);
        }
    }

    get name() {
        return this.form.get("name");
    }

    get eventQuery() {
        return this.form.get("eventQuery");
    }
}
