import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Table } from "../s25-table/Table";
import { Bind } from "../../decorators/bind.decorator";
import { GenericTableButtonComponent } from "../s25-table/generics/generic.table.button.component";
import { CacheRepository } from "../../decorators/cache.decorator";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { GridListItem, GridsService } from "../../services/grids.service";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";

@Component({
    selector: "s25-ng-academic-grids-list",
    template: `
        @if (isInit) {
            <s25-ng-button [type]="'primary'" (click)="createGrid()" class="create">Add Grid</s25-ng-button>
            <s25-ng-table
                [dataSource]="tableModel"
                [columnSortable]="true"
                [hasFilter]="true"
                [hasRefresh]="true"
                [unlimitedWidth]="true"
                [noResultsMessage]="'You do not have any grids yet'"
            ></s25-ng-table>

            <s25-ng-modal #editModal [size]="'xs'">
                <ng-template #s25ModalBody let-mode="mode" let-grid="grid">
                    <s25-ng-create-academic-grid [grid]="grid" [mode]="mode" (done)="editModal.close($event)" />
                </ng-template>
            </s25-ng-modal>

            <s25-ng-modal #confirmModal [title]="confirm?.title" [type]="'confirm'" [size]="'sm'">
                {{ confirm?.message }}
            </s25-ng-modal>

            <s25-ng-modal #shareModal [title]="'Share Grid'" [size]="'xs'">
                <ng-template #s25ModalBody let-grid="grid">
                    <s25-ng-share-academic-grid
                        [id]="grid.id"
                        [(sharedWith)]="grid.sharedWith"
                        (saved)="shareModal.close()"
                        (cancelled)="shareModal.close()"
                    />
                </ng-template>
            </s25-ng-modal>
        }
    `,
    styles: `
        .create {
            padding-block-end: 1rem;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25AcademicGridsListComponent implements OnInit {
    @Input({ required: true }) selectGrid: (grid: GridListItem) => Promise<void>;

    @ViewChild(S25TableComponent) table: S25TableComponent;
    @ViewChild("editModal") editModal: S25ModalComponent<GridListItem>;
    @ViewChild("confirmModal") confirmModal: S25ModalComponent<boolean>;
    @ViewChild("shareModal") shareModal: S25ModalComponent<boolean>;

    isInit = false;
    grids: GridListItem[];
    tableModel: Table.DataSource;
    confirm: { title: string; message: string };
    columns = [
        { id: "name", header: "Name" },
        { id: "eventSearch", header: "Event Search" },
        { id: "type", header: "Type" },
        GenericTableButtonComponent.Column("View", this.onViewClick),
        GenericTableButtonComponent.Column("Edit", this.onEditClick),
        GenericTableButtonComponent.Column("Share", this.onShareClick),
        GenericTableButtonComponent.Column("Delete", this.onDeleteClick, "danger--outline"),
    ];

    ngOnInit() {
        this.tableModel = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: this.columns,
        };
        this.isInit = true;
    }

    async createGrid() {
        const newGrid = await this.editModal.open({ title: "Create Grid", templateData: { mode: "create" } });
        if (!newGrid) return; // User did not create a new grid
        this.table.addRow(this.mapToRow(newGrid));
        this.grids.push(newGrid);
        this.updateColumns();
    }

    @Bind
    async onEditClick(row: Row) {
        const newGrid = await this.editModal.open({
            title: `Edit Grid: ${row.data.grid.name}`,
            templateData: { mode: "edit", grid: row.data.grid },
        });
        this.table.updateRow(this.mapToRow(newGrid));
    }

    @Bind
    async onShareClick(row: Row) {
        this.shareModal.open({ templateData: { grid: row.data.grid } });
    }

    @Bind
    onViewClick(row: Row) {
        return this.selectGrid(row.data.grid);
    }

    @Bind
    async onDeleteClick(row: Row) {
        this.confirm = {
            title: "Confirm Deletion",
            message: `Are you sure you want to delete "${row.data.grid.name}"?`,
        };
        const ok = await this.confirmModal.open();
        if (!ok) return;
        const [_, err] = await GridsService.delete(row.data.grid.id);
        if (err) {
            alert("Something went wrong when deleting the grid.");
            return;
        }
        this.table.removeRow(row);
        this.grids.splice(this.grids.indexOf(row.data.grid), 1);
        this.updateColumns();
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("GridsService");
        const [maybeGrid] = await Promise.all([GridsService.getList()]);
        const [gridData, gridErr] = maybeGrid;
        if (gridErr) {
            alert("Something went wrong when fetching grids");
            return;
        }
        this.grids = gridData;

        this.updateColumns();

        // Sort by name, but put all owned grids first
        this.grids.sort((a, b) => a.name.localeCompare(b.name));
        this.grids.sort((a, b) => (a.isOwner === b.isOwner ? 0 : a.isOwner ? -1 : 1));

        const rows: Table.Row[] = gridData.map(this.mapToRow);
        return { rows };
    }

    mapToRow(grid: GridListItem): Row {
        return {
            id: grid.id,
            name: grid.name,
            data: { grid },
            cells: {
                name: { text: grid.name },
                eventSearch: { text: grid.eventQuery.queryName },
                type: { text: grid.type },
                edit: { if: grid.isOwner },
                delete: { if: grid.isOwner },
                share: { if: grid.isOwner },
            },
        };
    }

    updateColumns() {
        // If user doesn't own any grids, hide Edit, Share, and Delete columns
        if (this.grids.every((grid) => !grid.isOwner)) {
            this.table.setColumns(this.columns.filter((col) => !["edit", "share", "delete"].includes(String(col.id))));
        } else {
            this.table.setColumns(this.columns.slice());
        }
    }
}

type Row = Table.Row<{ grid: GridListItem }, "name" | "eventSearch" | "type" | "view" | "edit" | "delete" | "share">;
