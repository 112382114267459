import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25AcademicGridsListComponent } from "./s25.academic.grids.list.component";
import { GridListItem } from "../../services/grids.service";
import { Bind } from "../../decorators/bind.decorator";
import { TelemetryService } from "../../services/telemetry.service";

@TypeManagerDecorator("s25-ng-academic-grids-page")
@Component({
    selector: "s25-ng-academic-grids-page",
    template: `
        <h1>Academic Grids</h1>
        @if (!chosenGrid) {
            <s25-ng-academic-grids-list [selectGrid]="selectGrid"></s25-ng-academic-grids-list>
        } @else {
            <s25-ng-schedule-only-grid [grid]="chosenGrid" (exit)="onGridExit()"></s25-ng-schedule-only-grid>
        }
    `,
    styles: ``,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25AcademicGridsPageComponent {
    @ViewChild(S25AcademicGridsListComponent) listComponent: S25AcademicGridsListComponent;

    chosenGrid: GridListItem;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {
        elementRef.nativeElement.angBridge = this;
    }

    @Bind
    async selectGrid(grid: GridListItem) {
        if (!grid) return;
        TelemetryService.sendWithSub("MPG", "Academic", "View");
        this.chosenGrid = grid;
        this.cd.detectChanges();
    }

    onGridExit() {
        this.chosenGrid = null; // Unloads the grid component
        this.cd.detectChanges();
    }
}
