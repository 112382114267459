import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-avail-legend")
@Component({
    selector: "s25-ng-academic-grids-legend",
    template: `
        <ul class="avail-legend">
            <li class="grid-color" data-type="event" [tabindex]="0">Section</li>
            <li class="grid-color" data-type="eventWithLocations" [tabindex]="0">Section With Location(s)</li>
            <li class="grid-color" data-type="closed" [tabindex]="0">Ad Hoc Occurrence</li>
            <li class="grid-color" data-type="blackout" [tabindex]="0">Different Meeting Pattern</li>
            <li class="grid-color" data-type="overlapping" [tabindex]="0">Overlapping Sections</li>
            <li class="grid-color" data-type="standardSchedule" [tabindex]="0">Standard Schedule</li>
            <li class="grid-color" data-type="conflict" [tabindex]="0">Standard Schedule Conflict</li>
            <li class="grid-color" data-type="instructorConflict" [tabindex]="0">Instructor Conflict</li>
        </ul>
    `,
    styles: `
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: grid;
            gap: 0.5rem;
        }

        li {
            margin: 0;
            padding: 0.5em;
            border-radius: 3px;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25AcademicGridsLegendComponent {}
