import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { GridListItem, GridsService } from "../../services/grids.service";
import { Grid } from "../s25-virtual-grid/s25.virtual.grid.component";
import { ScheduleOnlyGrid } from "./s25.academic.grids.util";
import { Bind } from "../../decorators/bind.decorator";
import { Item } from "../../pojo/Item";
import { TooltipResponse, tooltipRowType } from "../../services/tooltip.service";
import { S25DowGridComponent } from "../s25-virtual-grid/s25.dow.grid.component";
import createFilter = ScheduleOnlyGrid.createFilter;
import { DropDownItem } from "../../pojo/DropDownItem";
import { S25AcademicGridsFilterComponent } from "./s25.academic.grids.filter.component";
import { S25Util } from "../../util/s25-util";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import { UserprefService } from "../../services/userpref.service";
import { MPGUtil } from "../s25-meeting-pattern-grid/s25.meeting.pattern.grid.util";

@Component({
    selector: "s25-ng-schedule-only-grid",
    template: `
        <h2 class="grid-name ngCenter">{{ grid.name }}</h2>
        @if (!prefs) {
            <s25-ng-loading-inline-static />
        } @else {
            <s25-ng-dow-pattern-grid
                [dataSource]="dowGridDataSource"
                [dows]="allDows"
                [visibleDows]="includedDows"
                [startHour]="prefs.startHour"
                [endHour]="prefs.endHour"
                [canDragX]="true"
                [canDragY]="false"
                [hasRefresh]="false"
                [hasUndo]="false"
                [allowOverlap]="false"
                [displayShadows]="false"
                [hasMinimap]="true"
                [pollForChanges]="false"
                [hasStandardSchedule]="true"
                [optionsLeftTemplate]="optionsLeft"
                [optionsRightTemplate]="optionsRight"
                [optionsBelowTemplate]="optionsBelow"
                [rowHeaderTemplate]="rowHeaderTemplate"
                [itemTemplate]="itemTemplate"
                [spanHours]="3"
                [findOverlap]="true"
                [createOverlapItems]="true"
            ></s25-ng-dow-pattern-grid>
        }

        <ng-template #optionsLeft let-defaultOptions="defaultOptions">
            <button class="aw-button aw-button--danger--outline exit" (click)="exit.emit()">Exit</button>
            @if (grid.isOwner) {
                <s25-ng-button [type]="'outline'" (click)="shareModal.open()">Share</s25-ng-button>
            }
            <ng-container [ngTemplateOutlet]="defaultOptions"></ng-container>
        </ng-template>

        <ng-template #optionsRight let-defaultOptions="defaultOptions">
            <s25-popover [modelBean]="{ popoverTemplate: legend }" [openTrigger]="'click'" [closeTrigger]="'click'">
                <s25-ng-button [type]="'outline'" [replaceClickEvent]="false" class="legend">Legend</s25-ng-button>
            </s25-popover>
            <ng-template #legend>
                <s25-ng-academic-grids-legend />
            </ng-template>
            <s25-ng-button [type]="'danger--outline'" [onClick]="checkConflicts">Check Conflicts</s25-ng-button>
            <ng-container [ngTemplateOutlet]="defaultOptions"></ng-container>
        </ng-template>

        <ng-template #optionsBelow let-defaultOptions="defaultOptions">
            <div class="optionsBelow">
                <div class="left">
                    <select
                        [(ngModel)]="mode"
                        class="cn-form__control"
                        (ngModelChange)="onModeChange()"
                        [attr.aria-label]="'Grid View'"
                    >
                        <option value="standard">Standard View</option>
                        <option value="instructor">Instructor View</option>
                    </select>
                </div>
                <div class="right">
                    <div class="activeFilters">
                        @for (filter of filters; track filter.label) {
                            @if (filter.active()) {
                                <div class="activeFilter">
                                    <s25-ng-button [type]="'none'" (click)="clearFilter(filter)">
                                        <s25-ng-icon [type]="'close'" />
                                    </s25-ng-button>
                                    <span>{{ filter.label }}: </span>
                                    <span [title]="filter.text()">{{ filter.text() }}</span>
                                </div>
                            }
                        }
                    </div>

                    <div class="filtersWrapper" s25-ng-click-outside (clickOutside)="toggleFilters(false)">
                        <button class="aw-button aw-button--outline toggle-filters" (click)="toggleFilters()">
                            <s25-ng-icon [type]="showFilters ? 'caretUp' : 'caretDown'"></s25-ng-icon>
                            Filters
                        </button>
                        @if (showFilters) {
                            <s25-ng-academic-grids-filter
                                [filters]="filters"
                                (apply)="applyFilters()"
                                (clear)="clearFilters()"
                            />
                        }
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #rowHeaderTemplate let-header="header">
            <div class="row-header">
                @if (mode === "standard") {
                    <s25-item-event
                        [modelBean]="{ itemId: header.data.eventId, itemName: header.data.header }"
                        [newTab]="true"
                        [dataService]="eventTooltipService"
                        [dataServiceContext]="header"
                    />
                } @else if (mode === "instructor") {
                    <s25-item-contact
                        [modelBean]="{
                            itemId: header.data.instructor.id,
                            itemName: header.data.instructor.name,
                        }"
                        [newTab]="true"
                    />
                }
            </div>
        </ng-template>

        <ng-template #itemTemplate let-item="item">
            @if (item.isOverlapItem) {
                <div class="item overlapItem grid-color" data-type="overlapping"></div>
            } @else {
                <div
                    class="item grid-color"
                    [attr.data-type]="getGridColorType(item)"
                    [class.schedule]="item.data._isStandardSchedule"
                    [class.nonStandardSchedule]="!item.data._fitsSchedule"
                    [class.shadow]="item.data._isShadow"
                    [class.hasOverlap]="!!item.overlapping?.length"
                >
                    <s25-item-event
                        [modelBean]="{ itemId: item.data.eventId, itemName: item.data.eventName }"
                        [newTab]="true"
                        [empty]="true"
                        [dataService]="eventTooltipService"
                        [dataServiceContext]="item"
                    >
                        @if (item.data._isShadow) {
                            <div class="shadowDow">{{ item.data._realItem.data.dow }}</div>
                        }
                    </s25-item-event>
                </div>
            }
        </ng-template>

        <ng-template #overlappingItems let-item="item">
            <ul class="overlapInfo">
                <li>
                    <s25-ng-button [type]="'link'" (click)="bringToFront(item)" [title]="'Bring To Front'">
                        {{ item.data.eventName }}
                    </s25-ng-button>
                </li>
                @for (overlapItem of item.overlapping; track overlapItem.id) {
                    <li>
                        <s25-ng-button [type]="'link'" (click)="bringToFront(overlapItem)" [title]="'Bring To Front'">
                            {{ overlapItem.data.eventName }}
                        </s25-ng-button>
                    </li>
                }
            </ul>
        </ng-template>

        <s25-ng-modal #conflictsModal [title]="'Conflicts'" [size]="'xs'">
            <ng-template #s25ModalBody let-conflicts="conflicts">
                @if (conflicts.instructorConflicts?.length) {
                    <p>
                        There are {{ conflicts.instructorConflicts.length }} outstanding sets of instructor conflicts.
                    </p>
                    <div class="instructorConflicts">
                        @for (conflict of conflicts.instructorConflicts; track $index) {
                            <div class="instructorConflict">
                                <label>Instructor:</label>
                                <span>{{ conflict.item.data.instructor.name }}</span>
                                <label>Meeting Pattern:</label>
                                <span>{{ conflict.item.data.dow }}</span>
                                <label>Time:</label>
                                <span>{{ conflict.time }}</span>
                                <label>Sections:</label>
                                <ul>
                                    @for (item of conflict.items; track item.id) {
                                        <li>{{ item.data.eventName }}</li>
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                }
            </ng-template>
        </s25-ng-modal>

        <s25-ng-modal #shareModal [title]="'Share Grid'" [size]="'xs'">
            <ng-template #s25ModalBody>
                <s25-ng-share-academic-grid
                    [id]="grid.id"
                    [(sharedWith)]="grid.sharedWith"
                    (saved)="shareModal.close()"
                    (cancelled)="shareModal.close()"
                />
            </ng-template>
        </s25-ng-modal>
    `,
    styles: `
        :host {
            --item-border-color: #333;
            --item-border: 1px solid var(--item-border-color);
            --item-shadow-background: #616161;
            --column-width: 50px;
            --conflict-background: #eee;
        }

        ::ng-deep .nm-party--on s25-ng-schedule-only-grid {
            --conflict-background: #222;
            --item-border-color: #000;
        }

        .optionsBelow {
            display: flex;
            justify-content: space-between;
            gap: 0.5rem;
            padding-block-end: 0.5rem;
        }

        .optionsBelow .right {
            display: flex;
            justify-content: end;
            gap: 0.5rem;
        }

        .filtersWrapper {
            position: relative;
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        .activeFilters {
            display: flex;
            justify-content: right;
            gap: 0.5rem;
            flex-wrap: wrap-reverse;
            padding-bottom: 0.5rem;
        }

        .activeFilter {
            font-size: 0.8em;
            border-radius: 1em;
            padding: 0.1rem 0.4rem 0 0.2rem;
            background: var(--cn-blue);
            margin-bottom: -0.05em;
            max-width: 20em;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            overflow: hidden;
            color: white;
            height: fit-content;
        }

        .activeFilter s25-ng-button {
            float: left;
            padding-top: 0.1em;
        }

        s25-ng-academic-grids-filter {
            position: absolute;
            z-index: 11;
            top: 100%;
            right: 0;
            margin-top: 0.5rem;
            border: 1px solid black;
        }

        .row-header {
            padding-inline-start: 0.5rem;
            cursor: pointer;
        }

        .item {
            height: calc(100% - 3 * var(--border-width));
            translate: 0 var(--border-width);
            border: var(--grid-color--border, var(--item-border));
        }

        .item.overlapItem {
            z-index: 10; /* Has to be in front of items */
            position: relative;
            height: calc(100% - 3 * var(--border-width));
            margin: calc(0px - var(--grid-color--border-width));
            box-sizing: content-box;
        }

        .item.shadow {
            text-align: center;
            position: relative;
        }

        .shadowDow {
            pointer-events: none;
            line-height: calc(var(--row-height) - 2 * var(--border-width));
            padding-inline: 0.25rem;
        }

        .item.hasOverlap .shadowDow {
            text-align: left;
        }

        ::ng-deep s25-ng-schedule-only-grid .item .s25-item-holder {
            width: 100%;
        }

        .instructorConflict {
            display: grid;
            grid-template-columns: 10em auto;
            padding: 0.5em;
            border: 1px solid black;
            background: var(--conflict-background);
            margin-top: 1rem;
        }

        .instructorConflict label {
            font-weight: bold;
        }

        .instructorConflict ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    `,
})
export class S25ScheduleOnlyGridComponent implements OnInit, OnChanges {
    @Input({ required: true }) grid: GridListItem;

    // Outputs
    @Output() exit = new EventEmitter<boolean>(); // Emitting will unload the component. Boolean indicates whether to refresh table in parent

    // Views
    @ViewChild(S25DowGridComponent) dowGridComponent: S25DowGridComponent<
        ScheduleOnlyGrid.HeaderData,
        ScheduleOnlyGrid.RowData,
        ScheduleOnlyGrid.ItemData
    >;
    @ViewChild("overlappingItems") overlappingItemsTemplate: TemplateRef<any>;
    @ViewChild("conflictsModal") conflictsModal: S25ModalComponent;

    // Variables
    prefs: Awaited<ReturnType<typeof GridsService.getPreferences>>;
    dowGridDataSource: ScheduleOnlyGrid.DataSource;
    allDows: string[];
    includedDows: string[];
    showFilters: boolean = false;
    filters: ScheduleOnlyGrid.Filter[];
    mode: "standard" | "instructor" = "standard";
    is24Hours: boolean;

    constructor(public cd: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.grid &&
            !changes.grid.firstChange &&
            changes.grid.previousValue?.id !== changes.grid.currentValue?.id
        ) {
            this.dowGridComponent.refresh();
        }
    }

    async ngOnInit() {
        this.dowGridDataSource = {
            getData: this.getGridData,
        };

        const [prefs, is24Hours] = await Promise.all([
            GridsService.getPreferences(),
            UserprefService.getIs24HourTime(),
        ]);
        this.prefs = prefs;
        this.is24Hours = is24Hours;
        this.cd.detectChanges();
    }

    @Bind
    async getGridData(query: Grid.DataQuery): Promise<ScheduleOnlyGrid.Data> {
        const [gridResult] = await Promise.all([GridsService.getData(this.grid.id)]);
        const data = gridResult.data;
        if (gridResult.error) {
            alert("Something went wrong when fetching grid data");
            this.exit.emit();
            return;
        }

        const { rows, items } = ScheduleOnlyGrid.getData(data);

        this.filters = this.initFilters(rows);

        const allDows = ScheduleOnlyGrid.getAllDows(items);
        this.allDows = allDows.all;
        this.includedDows = allDows.included;
        this.cd.detectChanges(); // Need to pass dows into DowGrid through template

        return { items, rows, message: rows.length === 0 && "No events in search" };
    }

    @Bind
    async eventTooltipService(
        itemType: Item.Id,
        eventId: number,
        reservationId: number,
        item: ScheduleOnlyGrid.Item,
    ): Promise<TooltipResponse> {
        const eventData = item.data;
        const rows: TooltipResponse["item"]["rows"]["data"] = [];

        if (eventData.eventTitle) {
            rows.push({
                name: "Title:",
                type: tooltipRowType.Raw,
                value: eventData.eventTitle,
            });
        }

        rows.push({
            name: "Head Count:",
            type: tooltipRowType.LabelledList,
            value: [
                { label: "Expected", value: eventData.expectedHeadCount || 0 },
                { label: "Registered", value: eventData.registeredHeadCount || 0 },
            ],
        });

        if (eventData.features?.length) {
            rows.push({
                name: "Features:",
                type: tooltipRowType.Bullets,
                value: eventData.features.map((feature) => ({ value: feature.name })),
            });
        }

        if (eventData.instructor) {
            const contact = eventData.instructor;
            rows.push({
                name: "Instructor:",
                type: tooltipRowType.Item,
                value: { itemTypeId: Item.Ids.Contact, itemId: contact.id, itemName: contact.name },
            });
        }

        if (eventData.locations?.length) {
            rows.push({
                name: "Locations:",
                type: tooltipRowType.Bullets,
                value: eventData.locations.map((location) => {
                    return {
                        nested: true,
                        value: {
                            type: tooltipRowType.Item,
                            value: { itemTypeId: Item.Ids.Location, itemId: location.id, itemName: location.name },
                        },
                    };
                }),
            });
        }

        if (eventData.sectionType) {
            rows.push({
                name: "Section Type:",
                type: tooltipRowType.Raw,
                value: eventData.sectionType,
            });
        }

        if (eventData.subjectCode) {
            rows.push({
                name: "Subject Code:",
                type: tooltipRowType.Raw,
                value: eventData.subjectCode,
            });
        }

        if (eventData.subTerm) {
            rows.push({
                name: "Subterm:",
                type: tooltipRowType.Raw,
                value: eventData.subTerm,
            });
        }

        if (eventData.courseReferenceNumber) {
            rows.push({
                name: "Course Reference Number:",
                type: tooltipRowType.Raw,
                value: eventData.courseReferenceNumber,
            });
        }

        if (eventData.reference) {
            rows.push({
                name: "Reference:",
                type: tooltipRowType.Raw,
                value: eventData.reference,
            });
        }

        if (item.overlapping?.length) {
            rows.push({
                name: "Overlapping Items:",
                type: tooltipRowType.Template,
                value: {
                    template: this.overlappingItemsTemplate,
                    context: { item },
                },
            });
        }

        return {
            item: {
                event_id: eventData.eventId,
                event_name: eventData.eventName,
                rows: { data: rows },
            },
        };
    }

    initFilters(rows: ScheduleOnlyGrid.Row[]) {
        const toItem = (id: number | string, name: string) => ({ itemId: id, itemName: name });

        const featureMap = new Map<number, DropDownItem>();
        const sectionTypeSet = new Set<string>();
        const instructorsMap = new Map<number, DropDownItem>();
        const subTermSet = new Set<string>();
        const subjectSet = new Set<string>();
        for (const row of rows) {
            for (const feature of row.data.features ?? []) {
                if (feature.name) featureMap.set(feature.id, toItem(feature.id, feature.name));
            }

            const instructor = row.data.instructor;
            if (instructor) {
                instructorsMap.set(instructor.id, toItem(instructor.id, instructor.name));
            }

            if (row.data.sectionType) sectionTypeSet.add(row.data.sectionType);
            if (row.data.subTerm) subTermSet.add(row.data.subTerm);
            if (row.data.subjectCode) subjectSet.add(row.data.subjectCode);
        }

        const features: DropDownItem[] = Array.from(featureMap.values());
        const instructors: DropDownItem[] = Array.from(instructorsMap.values());
        const sectionTypes: DropDownItem[] = Array.from(sectionTypeSet).map((s) => toItem(s, s));
        const subTerms: DropDownItem[] = Array.from(subTermSet).map((s) => toItem(s, s));
        const subjects: DropDownItem[] = Array.from(subjectSet).map((s) => toItem(s, s));

        for (const type of [features, sectionTypes, instructors, subTerms, subjects]) {
            type.sort((a, b) => a.itemName.localeCompare(b.itemName));
        }

        return [
            createFilter({ type: "text", label: "Name", transform: (row) => row.data.eventName }),
            createFilter({ type: "numberRange", label: "EHC", transform: (row) => row.data.expectedHeadCount }),
            createFilter({ type: "numberRange", label: "RHC", transform: (row) => row.data.registeredHeadCount }),
            createFilter({
                type: "dropdownMultiselect",
                label: "Feature",
                transform: (row) => row.data.features.map((req) => req.id),
                data: { items: features, placeholder: "Features" },
            }),
            createFilter({
                type: "dropdownMultiselect",
                label: "Instructor",
                transform: (row) => [row.data.instructor?.id],
                data: { items: instructors, placeholder: "Contacts" },
            }),
            createFilter({
                type: "dropdownMultiselect",
                label: "Section Type",
                transform: (row) => [row.data.sectionType],
                data: { items: sectionTypes, placeholder: "Section Types" },
            }),
            createFilter({
                type: "dropdownMultiselect",
                label: "Subject Code",
                transform: (row) => [row.data.subjectCode],
                data: { items: subjects, placeholder: "Subjects" },
            }),
            createFilter({
                type: "dropdownMultiselect",
                label: "Subterm",
                transform: (row) => [row.data.subTerm],
                data: { items: subTerms, placeholder: "Subterms" },
            }),
            createFilter({ type: "text", label: "CRN", transform: (row) => row.data.courseReferenceNumber }),
            createFilter({ type: "yesNo", label: "Locations", transform: (row) => !!row.data.locations.length }),
        ];
    }

    toggleFilters(open?: boolean) {
        this.showFilters = open === undefined ? !this.showFilters : open;
        this.cd.detectChanges();
    }

    @Bind
    applyFilters() {
        if (this.mode === "standard") {
            this.dowGridComponent.filterRows((row) =>
                this.filters.every((filter) => !filter.active() || filter.filter(row)),
            );
        } else if (this.mode === "instructor") {
            const instructors = new Set<number>();
            this.dowGridComponent.filterItems((item) => {
                const keep = this.filters.every((filter) => !filter.active() || filter.filter(item));
                if (keep) instructors.add(item.data.instructor?.id);
                return keep;
            }, false);
            this.dowGridComponent.filterRows((row) => {
                if (!row.data.instructor?.id) return false;
                return instructors.has(row.data.instructor.id);
            });
        }
        this.showFilters = false;
    }

    @Bind
    clearFilters() {
        if (this.mode === "standard") {
            this.dowGridComponent.clearRowFilter();
        } else if (this.mode === "instructor") {
            this.dowGridComponent.clearItemFilter(false);
            this.dowGridComponent.clearRowFilter();
        }
    }

    clearFilter(filter: ScheduleOnlyGrid.Filter): void {
        S25AcademicGridsFilterComponent.clearFilter(filter);
        this.applyFilters();
    }

    onModeChange() {
        if (!this.dowGridComponent.data.items?.length) return;
        if (this.mode === "instructor") this.toInstructorMode();
        else if (this.mode === "standard") this.toStandardMode();
    }

    toInstructorMode() {
        const rows = this.dowGridComponent.allRows();
        const items = this.dowGridComponent.allItems();

        // Sort rows by instructor name
        ScheduleOnlyGrid.sortRowsAsInstructors(rows);

        // Hide duplicate instructor rows & get index of instructor row
        const uniqueInstructors = new Set<number>();
        const instructorIndex = new Map<number, number>();
        for (const [i, row] of S25Util.array.enumerate(rows)) {
            const instructor = row.data.instructor?.id;
            if (!instructor || uniqueInstructors.has(instructor)) {
                row.hidden = true;
            } else {
                instructorIndex.set(instructor, i);
                uniqueInstructors.add(instructor);
            }
        }

        // Update position of items
        for (const item of items) {
            item.hidden = !item.data.instructor && !item.data._isStandardSchedule;
            if (item.data.instructor) {
                item.top = (instructorIndex.get(item.data.instructor.id) / rows.length) * 100;
            }
        }

        this.dowGridComponent.addShadows();

        this.dowGridComponent.staticRefresh(this.dowGridComponent.data);
    }

    toStandardMode() {
        const rows = this.dowGridComponent.allRows();
        const items = this.dowGridComponent.allItems();

        // Remove shadows
        this.dowGridComponent.removeShadows();

        // Sort rows by event name
        ScheduleOnlyGrid.sortRowsAsEvents(rows);

        // Un-hide rows & get index of profile row
        const profileIndex = new Map<number, number>();
        for (const [i, row] of S25Util.array.enumerate(rows)) {
            row.hidden = false;
            profileIndex.set(row.data.profileId, i);
        }

        // Update position of items
        for (const item of items) {
            item.hidden = false;
            if (item.data.profileId) {
                item.top = (profileIndex.get(item.data.profileId) / rows.length) * 100;
            }
        }

        this.dowGridComponent.staticRefresh(this.dowGridComponent.data);
    }

    bringToFront(item: ScheduleOnlyGrid.Item): void {
        this.dowGridComponent.bringToFront(item);
    }

    @Bind
    async checkConflicts() {
        const instructorConflicts = this.checkInstructorConflicts();
        if (!instructorConflicts?.length) return;
        await this.conflictsModal.open({ templateData: { conflicts: { instructorConflicts } } });
    }

    checkInstructorConflicts() {
        const conflicts: { item: ScheduleOnlyGrid.Item; items: ScheduleOnlyGrid.Item[]; time: string }[] = [];

        // Generate shadows to help with overlap checks
        if (this.mode !== "instructor") {
            this.dowGridComponent.addShadows();
        }

        const items = this.dowGridComponent.allItems();

        const byInstructor = S25Util.array.groupBy(items, (item) => item.data.instructor?.id);
        for (const instructor of S25Util.values(byInstructor)) {
            instructor.sort((a, b) => a.left - b.left);
            for (const [i, a] of S25Util.array.enumerate(instructor)) {
                if (a.data._isShadow || !a.data.instructor?.id) continue;
                const conflictItems: ScheduleOnlyGrid.Item[] = [];
                for (let j = i + 1; j < instructor.length; j++) {
                    const b = instructor[j];
                    if (a.id === b.id || a.linkedItems.has(b.id)) continue;
                    if (!b.data.instructor?.id) continue;
                    if (a.left + a.width <= b.left) continue;
                    if (a.data.startDate >= b.data.endDate || a.data.endDate <= b.data.startDate) continue;
                    if (!ScheduleOnlyGrid.doOccurrencesOverlap(a.data.occurrences, b.data.occurrences)) continue;

                    b.data.instructorConflict = true;
                    conflictItems.push(b);
                }
                if (!conflictItems.length) continue;
                a.data.instructorConflict = true;
                const startTime = S25Util.date.toTimeStrFromHours(a.data.startHour, this.is24Hours);
                const endTime = S25Util.date.toTimeStrFromHours(a.data.endHour, this.is24Hours);
                conflicts.push({
                    item: a,
                    items: [a, ...conflictItems],
                    time: `${startTime} - ${endTime}`,
                });
            }
        }

        // Remove shadows
        if (this.mode !== "instructor") {
            this.dowGridComponent.removeShadows();
        }

        return conflicts;
    }

    getGridColorType(item: ScheduleOnlyGrid.Item): string {
        if (item.data._isStandardSchedule) return "standardSchedule";
        if (!item.data._fitsSchedule) return "conflict";
        if (item.data.instructorConflict) return "instructorConflict";
        if (item.data._isShadow) return "blackout";
        if (item.data.adHoc) return "closed";
        if (!!item.data.locations.length) return "eventWithLocations";
        return "event";
    }
}
