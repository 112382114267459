import { Proto } from "../../pojo/Proto";
import { GridDataResponse, GridReservation, GridsService } from "../../services/grids.service";
import { DowGrid } from "../s25-virtual-grid/s25.dow.grid.component";
import { Grid } from "../s25-virtual-grid/s25.virtual.grid.component";
import { EventSummary, EventSummaryService } from "../s25-swarm-schedule/s25.event.summary.service";
import { S25Util } from "../../util/s25-util";
import { BOARD_CONST } from "../s25-swarm-schedule/s25.board.const";
import { BoardUtil } from "../s25-swarm-schedule/s25.board.util";
import { S25Const } from "../../util/s25-const";
import { CustomAttributes } from "../../pojo/CustomAttributes";
import { computed, signal, Signal, WritableSignal } from "@angular/core";
import { DropDownItem } from "../../pojo/DropDownItem";
import { RecordType } from "../s25-event/ProfileI";
import { MPGUtil } from "../s25-meeting-pattern-grid/s25.meeting.pattern.grid.util";

export namespace ScheduleOnlyGrid {
    import Unwrap = Proto.Unwrap;
    import DowNumber = Proto.DowNumber;
    import TimeSet = EventSummary.TimeSet;
    import ISODateString = Proto.ISODateString;

    export type DataSource = DowGrid.DataSource<HeaderData, RowData, ItemData>;

    export type Data = DowGrid.Data<HeaderData, RowData, ItemData>;
    export type Row = Grid.Row<RowData>;
    export type Item = DowGrid.Item<ItemData>;

    export type HeaderData = {};
    export type RowData = TooltipData & { header: string; profileId: number };
    export type ItemData = DowGrid._CustomItemData<DowGrid.CustomItemData> &
        TooltipData & {
            profileId: number;
            boundProfiles: number[];
            adHoc: boolean;
            instructorConflict?: boolean;
            startDate: ISODateString;
            endDate: ISODateString;
            occurrences: EventData["profiles"][number]["reservations"];
        };

    export type TooltipData = {
        eventId: number;
        eventName: string;
        eventTitle: string;
        reference: string;
        expectedHeadCount: number;
        registeredHeadCount: number;
        features: { id: number; name: string }[];
        subjectCode: string;
        instructor?: EventData["roles"][number];
        courseReferenceNumber?: string;
        subTerm?: string;
        sectionType?: string;
        locations: { id: number; name: string }[];
    };

    type EventData = Unwrap<Awaited<ReturnType<typeof GridsService.getData>>>["events"][number];

    type ProfileData = { data: GridDataResponse; event: EventData; profile: EventData["profiles"][number] };

    export type Filter = {
        label: string;
        type: "text" | "numberRange" | "contact" | "dropdownMultiselect" | "yesNo";
        value: WritableSignal<any>;
        active: Signal<boolean>;
        clear?: () => void;
        text: Signal<string>;
        filter: (row: Row | Item) => boolean;
        data?: unknown;
    };

    export function getData(data: GridDataResponse): { rows: Row[]; items: Item[] } {
        for (const event of data.events) event.name = event.name?.trim() || "";
        data.events.sort((a, b) => a.name.localeCompare(b.name));
        const profiles = S25Util.array.flatten(
            data.events.map((event) =>
                event.profiles
                    .filter((profile) => !!profile.reservations?.length)
                    .map((profile) => ({ data, event, profile })),
            ),
        );

        const rows: Row[] = getRows(profiles);
        const items: Item[] = getItems(profiles);

        return { rows, items };
    }

    export function getRows(profiles: ProfileData[]): Row[] {
        return profiles.map(mapProfileToRow);
    }

    export function mapProfileToRow(data: {
        data: GridDataResponse;
        event: EventData;
        profile: EventData["profiles"][number];
    }): Row {
        let header = data.event.name;
        if (data.event.profiles.length > 1) header += ` (${data.profile.name})`;
        return {
            id: data.profile.id,
            data: {
                header,
                profileId: data.profile.id,
                ...getTooltipData(data),
            },
        };
    }

    export function sortRowsAsEvents(rows: Row[]): void {
        rows.sort((a, b) => {
            if (!a.data.eventName) return 1;
            if (!b.data.eventName) return -1;
            if (!a.data.eventName && !b.data.eventName) return 0;
            return a.data.eventName.localeCompare(b.data.eventName);
        });
    }

    export function sortRowsAsInstructors(rows: Row[]): void {
        rows.sort((a, b) => {
            if (!a.data?.instructor) return 1;
            if (!b.data?.instructor) return -1;
            if (!a.data?.instructor && !b.data?.instructor) return 0;
            return a.data.instructor?.name?.localeCompare(b.data.instructor?.name);
        });
    }

    export function getItems(profiles: ProfileData[]): Item[] {
        const items = S25Util.array.flatten(profiles.map(mapProfileToItems));
        linkItems(items);

        return items;
    }

    export function mapProfileToItems(
        data: ProfileData,
        index: number,
        profiles: { event: EventData; profile: EventData["profiles"][number] }[],
    ): Item[] {
        const occs = mapOccurrenceDates(data.profile.reservations);
        const orderedDowPatterns = BoardUtil.getDowPatterns(occs);
        const timeSets = EventSummaryService._adhocSummaryModel(occs, -1, 99_999, orderedDowPatterns, true, true);

        return timeSets.map((set, i) => {
            return {
                id: `${data.profile.id}-${i}`,
                top: (index / profiles.length) * 100,
                height: 100 / profiles.length,
                draggable: false,
                linkedItems: new Set(),
                data: getItemData(data, set),
            } as Item;
        });
    }

    export function getItemData(data: ProfileData, timeSet: TimeSet): ItemData {
        const { profile, event } = data;
        const earliestReservation = profile.reservations.reduce(
            (earliest, res) => (res.start < earliest ? res.start : earliest),
            profile.reservations[0].start,
        );
        const lastReservation = profile.reservations.reduce(
            (latest, res) => (res.end > latest ? res.end : latest),
            profile.reservations[0].end,
        );

        return {
            profileId: profile.id,
            boundProfiles: profile.boundProfiles,
            adHoc: profile.recType !== RecordType.RecurrenceGrammar,
            dow: getDowFromTimeSet(timeSet),
            startHour: S25Util.date.timeToHours(timeSet.startTime),
            endHour: S25Util.date.timeToHours(timeSet.endTime),
            startDate: earliestReservation,
            endDate: lastReservation,
            occurrences: profile.reservations,
            ...getTooltipData(data),
        };
    }

    // Link bound items with the same patterns and times
    export function linkItems(items: Item[]) {
        const itemsByProfile = S25Util.array.groupBy(items, (item) => item.data.profileId);

        const matches = (a: Item, b: Item) =>
            a.data.dow === b.data.dow && a.data.startHour === b.data.startHour && a.data.endHour === b.data.endHour;

        for (const item of items) {
            if (!item.data.boundProfiles?.length) continue;
            for (const profile of item.data.boundProfiles) {
                const boundItems = itemsByProfile[profile];
                for (const binding of boundItems ?? []) {
                    if (!matches(item, binding)) return;
                    item.linkedItems.add(binding.id);
                    binding.linkedItems.add(item.id);
                }
            }
        }
    }

    export function getTooltipData(data: ProfileData): TooltipData {
        const { event, profile } = data;
        const roleById = new Map(S25Util.array.forceArray(event.roles).map((role) => [role.type, role]));
        const attributeById = new Map(S25Util.array.forceArray(event.attributes).map((ca) => [ca.id, ca]));
        const primaryOrg = event.primaryOrganization;

        const locations: TooltipData["locations"] = [];
        const locationIds = new Set<number>();
        for (const res of profile.reservations) {
            for (const location of res.locations || []) {
                if (locationIds.has(location.id)) continue;
                locationIds.add(location.id);
                locations.push(location);
            }
        }

        return {
            eventId: event.id,
            eventName: event.name,
            eventTitle: event.title,
            reference: event.locator,
            expectedHeadCount: Number(profile.expectedHeadCount),
            registeredHeadCount: Number(profile.registeredHeadCount),
            features: data.profile.featurePreferences,
            subjectCode: primaryOrg?.name,
            instructor: roleById.get(S25Const.instructorRole.event),
            courseReferenceNumber: attributeById.get(CustomAttributes.system.uniqueSectionId.id)?.value,
            subTerm: attributeById.get(CustomAttributes.system.subTerm.id)?.value,
            sectionType: attributeById.get(CustomAttributes.system.sectionType.id)?.value,
            locations,
        };
    }

    export function getDowFromTimeSet(timeSet: EventSummary.TimeSet): string {
        if (timeSet.pattern) {
            const profileCode = EventSummaryService.adhocSummaryModelToProfileCode(timeSet);
            const dow = S25Const.dows.filter((dow) => profileCode.includes(dow));
            return BoardUtil.daysOfWeekMap(dow.join(" ") + " ");
        } else {
            let dows = timeSet.map((occ) => S25Util.date.parseDropTZ(occ.reservation_start_dt).getDay() as DowNumber);
            dows = S25Util.array.unique(dows).sort();
            const dowsAbbr = dows.map((dow) => BOARD_CONST.dowInt2Abbr[dow]);
            return BoardUtil.daysOfWeekMap(dowsAbbr.join(" ") + " ");
        }
    }

    export function getAllDows(items: { data?: { dow: string } }[]): { all: string[]; included: string[] } {
        // Make sure all present dow patterns are included
        const all = [...BOARD_CONST.allDows];
        const allDowsSet = new Set(all);
        const includedDowsSet = new Set<string>();
        for (let item of items) {
            if (!item.data) continue;
            includedDowsSet.add(item.data.dow);
            if (!allDowsSet.has(item.data.dow)) {
                all.push(item.data.dow);
                allDowsSet.add(item.data.dow);
            }
        }

        const included = Array.from(includedDowsSet);
        const allDowsOrder = new Map(all.map((dow, i) => [dow, i]));
        included.sort((a, b) => allDowsOrder.get(a) - allDowsOrder.get(b));

        return { all, included };
    }

    export function createFilter(options: {
        type: Filter["type"];
        label: string;
        transform?: (item: Row | Item) => any;
        data?: unknown;
    }): Filter {
        const { type, label, transform, data } = options;
        switch (type) {
            case "text":
                const textValue = signal<string>(undefined);
                return {
                    label,
                    type,
                    value: textValue,
                    active: computed(() => !!textValue()),
                    text: computed(() => textValue() as string),
                    filter: (row) => !!transform(row)?.toLowerCase().includes(textValue().toLowerCase()),
                };
            case "numberRange":
                const numberRange = signal<{ min: WritableSignal<number>; max: WritableSignal<number> }>({
                    min: signal(null),
                    max: signal(null),
                });
                return {
                    label,
                    type,
                    value: numberRange,
                    active: computed(() => numberRange().min() !== null || numberRange().max() !== null),
                    clear: () => numberRange.set({ min: signal(null), max: signal(null) }),
                    text: computed(() => {
                        const [min, max] = [numberRange().min(), numberRange().max()];
                        if (min === null) return `< ${max}`;
                        if (max === null || max < min) return `> ${min}`;
                        return `${min} - ${max}`;
                    }),
                    filter: (row) => {
                        const [min, max] = [numberRange().min(), numberRange().max()];
                        const value = transform(row);
                        if (isNaN(value)) return false;
                        if (min === null) return value <= max;
                        if (max === null || max < min) return value >= min;
                        return value >= min && value <= max;
                    },
                };
            case "contact":
                const contactValue = signal<DropDownItem>(undefined);
                return {
                    label,
                    type,
                    value: contactValue,
                    active: computed(() => !!contactValue()),
                    text: computed(() => contactValue().itemName),
                    filter: (row) => {
                        return transform(row) === contactValue().itemId;
                    },
                };
            case "dropdownMultiselect":
                const multiselectValue = signal<DropDownItem[]>(undefined);
                const multiselectIds = computed(() => new Set(multiselectValue().map((item) => String(item.itemId))));
                return {
                    label,
                    type,
                    value: multiselectValue,
                    active: computed(() => !!multiselectValue()?.length),
                    text: computed(() =>
                        multiselectValue()
                            .map((item) => item.itemName)
                            .join(", "),
                    ),
                    filter: (row) => {
                        return transform(row).some((id: number | string) => multiselectIds().has(String(id)));
                    },
                    data,
                };
            case "yesNo":
                const yesNoValue = signal<boolean>(undefined);
                return {
                    label,
                    type,
                    value: yesNoValue,
                    active: computed(() => yesNoValue() !== undefined),
                    text: computed(() => (yesNoValue() ? "Yes" : "No")),
                    filter: (row) => transform(row) === yesNoValue(),
                };
        }
    }

    export function mapOccurrenceDates(occs: GridReservation[]) {
        return occs.map((occ) => ({ reservation_start_dt: occ.start, reservation_end_dt: occ.end }));
    }

    export function doOccurrencesOverlap(a: GridReservation[], b: GridReservation[]) {
        return MPGUtil.doOccurrencesOverlap(mapOccurrenceDates(a), mapOccurrenceDates(b));
    }
}
